import { Loader } from "@/components/index-page/cards/card/card-components";
import { useIndexByQuery } from "@/hooks/useIndexByQuery";
import { fetchHbCards } from "@/redux/thunks/fetchHbCards";
import { isFoundation } from "@/shared/constants/routes";
import { useRouter } from "next/router";
import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

export const HbInfiniteScroll = ({ children, offset, setOffset }) => {
  const { index } = useIndexByQuery();

  const {
    hbTags,
    hbCards: {
      isBookmarked,
      count,
      loading,
      data: { length },
    },
    searchInput: { filterSearch, searchOpen },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { pathname } = useRouter();

  const fetchMoreData = useCallback(async () => {
    dispatch(
      fetchHbCards(
        offset + 1,
        index,
        isBookmarked || filterSearch ? undefined : hbTags,
        isBookmarked,
        filterSearch,
        isFoundation(pathname) ? true : false
      )
    );
    setOffset(offset + 1);
  });

  return (
    <InfiniteScroll
      style={{
        overflow: "initial",
      }}
      hasMore={
        loading
          ? false
          : searchOpen &&
            (filterSearch === undefined || filterSearch.trim() === "")
          ? false
          : count > length
      }
      dataLength={length}
      next={fetchMoreData}
      loader={
        <Loader onClick={fetchMoreData}>
          <button>Загрузить ещё</button>
        </Loader>
      }
      scrollThreshold="500px"
    >
      {children}
    </InfiniteScroll>
  );
};
