import {
  BoxTune,
  Head,
  SearchDialogSt,
} from "../index-page/search/popup/components";
import { DialogContent, Slide } from "@mui/material";
import { OpenSettings } from "./AnimateSettingsWr";
import { useContext } from "react";
import styled from "styled-components";
import { HbSettings } from "./filterTags/Settings";

export const MobileSettings = () => {
  const { openSettings, setOpenSettings } = useContext(OpenSettings);
  const handleClose = () => setOpenSettings(false);
  return (
    <Dialog
      fullScreen={true}
      onClose={handleClose}
      id="mobile-settings-hb"
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
      transitionDuration={170}
      open={openSettings}
      scroll={"body"}
    >
      <Box>
        <Header>
          <span># Настроить</span>
        </Header>
        <DialogContent scroll="paper">
          <HbSettings />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

const Dialog = styled(SearchDialogSt)``;
const Box = styled(BoxTune)``;
const Header = styled(Head)``;
