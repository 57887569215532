import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Menu } from "@mui/material";
import styled from "styled-components";

export const Wr = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;
  margin: 44px auto 100px;
  /* min-height: calc(100vh - 260px); */
  @media ${breakpoints.desktopXl} {
    gap: 2.189vw;
    margin: 3.44vw auto 7.819vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 2.737vw;
    margin: 4.301vw auto 9.775vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 3.769vw;
    margin: 5.922vw auto 13.459vw;
  }
  @media ${breakpoints.mobile} {
    margin: 6.27vw auto;
    gap: 8.777vw;
  }
`;

export const TagsWr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media ${breakpoints.desktopXl} {
    gap: 1.251vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.564vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.153vw;
  }
  @media ${breakpoints.mobile} {
    gap: 5.016vw;
  }
`;
export const TagsTitle = styled.h2`
  ${fonts.manrope7}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--wh2);
`;
export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  max-width: 532px;
  @media ${breakpoints.desktopXl} {
    max-width: 41.595vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 52.004vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 71.602vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 166.771vw;
  }
`;
// width: 803px;
//   @media ${breakpoints.desktopXl} {
//     width: 62.783vw;
//   }
//   @media ${breakpoints.tabletL} {
//     width: 78.495vw;
//   }
//   @media ${breakpoints.tabletM} {
//     width: 108.075vw;
//   }
//   @media ${breakpoints.mobile} {
//     width: 251.724vw;
//   }
export const TagsChecked = styled(Tags)`
  will-change: width;
  flex-wrap: nowrap;
  ${({ isSettings }) =>
    isSettings
      ? `
  width: 803px;
  @media ${breakpoints.desktopXl} {
    width: 62.08vw;
    width: 62.08vw;
  }
  @media ${breakpoints.tabletL} {
    width: 77.615vw;
  }
  @media ${breakpoints.tabletM} {
    width: 70.256vw;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
  }
  `
      : `
  max-width: 803px;
  @media ${breakpoints.desktopXl} {
    max-width: 62.08vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 77.615vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 70.256vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 121.317vw;
  }
  
  
  `}
  @media ${breakpoints.mobile} {
    flex-wrap: wrap;
  }

  ${({ hideOnSearchOpen }) =>
    hideOnSearchOpen &&
    `
  width: 0 !important;
  opacity: 0;
  `};
  transition: padding-left 0.3s;
  ${({ addMargin }) =>
    addMargin &&
    `
   margin-right: 4px;
   @media ${breakpoints.desktopXl} {
     margin-right: 0.313vw;
    }
    @media ${breakpoints.tabletL} {
    margin-right: 0.391vw;
  }
  @media ${breakpoints.tabletM} {
    margin-right: 0.538vw;
  }
  @media ${breakpoints.mobile} {
    margin-right: 1.254vw;
  }
  `}/* ${({ addPadding }) =>
    addPadding &&
    `
   padding-left: 30px;
   @media ${breakpoints.desktopXl} {
       padding-left: 2.346vw;
  }
  @media ${breakpoints.tabletL} {
       padding-left: 2.933vw;
  }
  @media ${breakpoints.tabletM} {
       padding-left: 4.038vw;
  }
  @media ${breakpoints.mobile} {
       padding-left: 0;
  }
  `} */
`;
export const Tag = styled.button`
  flex-shrink: 0;
  max-width: 100%;

  ${({ hide }) =>
    hide &&
    `
    opacity: 0;
    & > div {
      height: 0 !important;
      padding: 0 !important;
    }
    `}

  & > div {
    ${fonts.manrope4}
    ${mixins.fs14}
    line-height: 114%;
    letter-spacing: 0.01em;
    color: var(--wh6);
    background-color: var(--wh04);
    transition: background-color 0.3s, color 0.3s;
    word-break: break-all;
    display: flex;
    align-items: center;
    border-radius: 24px;
    height: 44px;
    padding: 0 28px;
    @media ${breakpoints.desktopXl} {
      border-radius: 1.876vw;
      height: 3.44vw;
      padding: 0 2.189vw;
    }
    @media ${breakpoints.tabletL} {
      border-radius: 2.346vw;
      height: 4.301vw;
      padding: 0 2.737vw;
    }
    @media ${breakpoints.tabletM} {
      border-radius: 3.23vw;
      height: 5.922vw;
      padding: 0 3.769vw;
    }
    @media ${breakpoints.mobile} {
      height: 11.285vw;
      padding: 0 6.897vw;
      border-radius: 7.524vw;
    }
    & > span {
      transition: transform 0.3s;
      @media ${breakpoints.mobile} {
        transform: translateX(-2.194vw);
      }
    }
    position: relative;
    ${({ withCross }) =>
      withCross &&
      `&::after {
      transition: opacity 0.3s;
      opacity: 0;
      content: "×";
      position: absolute;
      ${mixins.fs16}
      line-height: 112%;
      color: var(--wh3);
      right: 21.5px;
      @media ${breakpoints.desktopXl} {
        right: 1.681vw;
      }
      @media ${breakpoints.tabletL} {
        right: 2.102vw;
      }
      @media ${breakpoints.tabletM} {
        right: 2.894vw;
      }
      @media ${breakpoints.mobile} {
        right: 5.329vw;
        opacity: 1;
      }
    }`}
  }

  &:hover {
    & > div {
      background-color: var(--wh08);
      ${({ checked }) =>
        checked && `background-color: var(--wh02); color: var(--wh4)`};
      &::after {
        opacity: 1;
      }
      & > span {
        transform: translateX(-7px);
        @media ${breakpoints.desktopXl} {
          transform: translateX(-0.547vw);
        }
        @media ${breakpoints.tabletL} {
          transform: translateX(-0.684vw);
        }
        @media ${breakpoints.tabletM} {
          transform: translateX(-0.942vw);
        }
        @media ${breakpoints.mobile} {
          transform: translateX(-2.194vw);
        }
      }
    }
  }
  &:active {
    & > div {
      background-color: var(--wh04);
    }
  }
`;

// export const ResetTags = styled.button`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-shrink: 0;
//   width: 32px;
//   height: 44px;
//   @media ${breakpoints.desktopXl} {
//     height: 3.44vw;
//     width: 2.502vw;
//   }
//   @media ${breakpoints.tabletL} {
//     height: 4.301vw;
//     width: 3.128vw;
//   }
//   @media ${breakpoints.tabletM} {
//     height: 5.922vw;
//     width: 4.307vw;
//   }
//   @media ${breakpoints.mobile} {
//     height: 13.793vw;
//     width: 10.031vw;
//   }
//   transition: color 0.3s;
//   color: var(--wh2);
//   &:hover {
//     color: var(--wh6);
//   }
//   &:active {
//     color: var(--wh2);
//   }
// `;

export const ButtonMore = styled(Tag)`
  border-radius: 100%;
  & > div {
    padding: 0;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${breakpoints.desktopXl} {
      width: 3.284vw;
      height: 3.284vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.106vw;
      height: 4.106vw;
    }
    @media ${breakpoints.tabletM} {
      width: 5.653vw;
      height: 5.653vw;
    }
    @media ${breakpoints.mobile} {
      width: 13.166vw;
      height: 13.166vw;
    }
  }
`;

export const TagSm = styled(Tag)`
  & > div {
    ${mixins.fs12}
    letter-spacing: 0.02em;
    padding: 0 16px;
    height: 32px;
    @media ${breakpoints.desktopXl} {
      height: 2.502vw;
      padding: 0 1.251vw;
    }
    @media ${breakpoints.tabletL} {
      height: 3.128vw;
      padding: 0 1.564vw;
    }
    @media ${breakpoints.tabletM} {
      height: 4.307vw;
      padding: 0 2.153vw;
    }
    @media ${breakpoints.mobile} {
      height: 10.031vw;
      padding: 0 5.016vw;
    }
  }
`;

export const WrChecked = styled.div`
  display: flex;
  align-items: flex-start;
  /* gap: 4px; */
`;

export const TagBoxHidden = styled.div`
  height: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

export const MenuSt = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
    margin-top: 8px;
    @media ${breakpoints.desktopXl} {
      margin-top: 0.625vw;
    }
    @media ${breakpoints.tabletL} {
      margin-top: 0.782vw;
    }
    @media ${breakpoints.tabletM} {
      margin-top: 1.077vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 2.508vw;
    }
  }
  & .MuiList-root {
    padding: 0;
    & .MuiButtonBase-root {
      padding: 0;
    }
  }
  & ${Tags} {
    overflow: hidden;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: var(--wh04);
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 0;
    gap: 0;
    border-radius: 24px;
    & ${Tag} {
      width: 100%;
      position: relative;
      & > div {
        background-color: transparent;
        border-radius: 0;
        height: 36px;
        padding-right: 52px;
        @media ${breakpoints.desktopXl} {
          padding-right: 4.066vw;
          height: 2.815vw;
        }
        @media ${breakpoints.tabletL} {
          padding-right: 5.083vw;
          height: 3.519vw;
        }
        @media ${breakpoints.tabletM} {
          padding-right: 6.999vw;
          height: 4.845vw;
        }
        @media ${breakpoints.mobile} {
          padding-right: 16.301vw;
          height: 11.285vw;
        }
      }
      &:hover {
        & > div {
          background-color: var(--wh08);
          ${({ checked }) =>
            checked && `background-color: var(--wh02); color: var(--wh4)`}
        }
        & > svg {
          opacity: 1;
        }
      }
      &:active {
        & > div {
          background-color: var(--wh04);
        }
      }
      & > svg {
        transition: opacity 0.3s;
        opacity: 0;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        transform: translateY(-50%);
        right: 19px;
        @media ${breakpoints.desktopXl} {
          width: 0.938vw;
          height: 0.938vw;
          right: 1.486vw;
        }
        @media ${breakpoints.tabletL} {
          width: 1.173vw;
          height: 1.173vw;
          right: 1.857vw;
        }
        @media ${breakpoints.tabletM} {
          width: 1.615vw;
          height: 1.615vw;
          right: 2.557vw;
        }
        @media ${breakpoints.mobile} {
          width: 3.762vw;
          height: 3.762vw;
          right: 5.956vw;
        }
      }
    }
  }
`;
