import api from "@/services/api";

import {
  fetchHbCuratedTagsSuccess,
  fetchHbRelatedTagsSuccess,
  fetchHbTagsError,
  fetchHbTagsLoading,
} from "../fetchSlices/hbTagsSlice";
import { parseBodyToTagsId } from "@/services/utils";

export const fetchHbTags = (tagsId, isFoundation = false) => {
  return async (dispatch) => {
    try {
      dispatch(fetchHbTagsLoading(true));
      const res = await api.get(
        `api/${isFoundation ? "artpreparation" : "handbook"}/tags/` +
          (tagsId ? `?${parseBodyToTagsId(tagsId)}` : "curated/")
      );
      dispatch(fetchHbTagsLoading(false));

      if (res.status === 200) {
        if (tagsId) {
          dispatch(fetchHbRelatedTagsSuccess(res.data));
        } else {
          dispatch(fetchHbCuratedTagsSuccess(res.data));
        }
      } else {
        dispatch(fetchHbTagsError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchHbTagsError(error.message));
      console.log(error);
    }
  };
};
