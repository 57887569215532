import { LoginPopup } from "@/components/header/LoginPopup";
import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints } from "@/styles/variables/variables";
import { useInViewport } from "ahooks";
import { useRef, useState } from "react";
import styled from "styled-components";

export const CardLocked = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const onOpen = () => setOpenDialog(true);

  const card = useRef(null);
  const [inViewport] = useInViewport(card, { threshold: 0.7 });

  return (
    <>
      <Wr hovered={inViewport} ref={card} onClick={onOpen}>
        <SvgSelector svg={"campus-card-locked"} />
        <Circle>
          <div></div>
          <SvgSelector svg={"campus-card-locked-lock"} />
        </Circle>
      </Wr>
      <LoginPopup open={openDialog} setOpen={setOpenDialog} />
    </>
  );
};
export const Circle = styled.div`
  & > div {
    grid-column: 1;
    grid-row: 1;
    transition: opacity 0.3s;
    opacity: 0.16;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.24) 0%,
      rgba(158, 50, 121, 0) 100%
    );
    width: 150px;
    height: 150px;
    @media ${breakpoints.desktopXl} {
      width: 11.73vw;
      height: 11.73vw;
    }
    @media ${breakpoints.tabletL} {
      width: 14.66vw;
      height: 14.66vw;
    }
    @media ${breakpoints.tabletM} {
      width: 20.19vw;
      height: 20.19vw;
    }
    @media ${breakpoints.mobile} {
      width: 47.02vw;
      height: 47.02vw;
    }
  }
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
  & > svg {
    overflow: visible;
    grid-column: 1;
    grid-row: 1;

    width: 16px;
    height: 16px;
    @media ${breakpoints.desktopXl} {
      width: 1.25vw;
      height: 1.25vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.56vw;
      height: 1.56vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.15vw;
      height: 2.15vw;
    }
    @media ${breakpoints.mobile} {
      width: 5.02vw;
      height: 5.02vw;
    }
  }
`;

const Wr = styled.button`
  position: relative;
  & > svg {
    width: 100%;
    height: 100%;
  }
  & #lock-top {
    transition: transform 0.3s;
    transform-origin: 75% center;
  }
  &:hover {
    ${Circle}>div {
      opacity: 0.28;
    }
  }
  &:active {
    ${Circle} {
      & > div {
        opacity: 0.16;
      }
      & #lock-top {
        transform: scale(-1, 1);
      }
    }
  }
  @media ${breakpoints.mobile} {
    ${({ hovered }) =>
      hovered &&
      `
        ${Circle} > div {
          opacity: 0.28;
        }
  `}
  }
`;
