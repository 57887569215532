import SvgSelector from "@/shared/UI/SvgSelector";
import { ROUTES, isHandbook } from "@/shared/constants/routes";
import { useRouter } from "next/router";
import { Slug } from "../components";

export const SlugLink = ({ slug }) => {
  const { pathname } = useRouter();
  return (
    <Slug
      // target="_blank"
      href={
        (isHandbook(pathname) ? ROUTES.HANDBOOK : ROUTES.FOUNDATION) +
        "/" +
        slug
      }
    >
      <SvgSelector svg="hb-card-slug" />
    </Slug>
  );
};
