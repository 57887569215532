import { resetFilterSearch, setFilterSearch } from "@/redux/searchInputSlice";
import { SearchBox } from "../search/SearchBox";
import HandbookCardsContainer from "./masonry/HandbookCardsContainer";
import Wrapper from "@/shared/UI/Wrapper";
import styled from "styled-components";
import { createContext, useMemo, useState } from "react";
import { Fade, useMediaQuery } from "@mui/material";
import { HbSettings } from "./filterTags/Settings";
import { breakpoints } from "@/styles/variables/variables";
import { MobileSettings } from "./MobileSettings";

export const OpenSettings = createContext(null);

export const AnimateSettingsWr = () => {
  const [openSettings, setOpenSettings] = useState(false);
  const value = useMemo(
    () => ({ openSettings, setOpenSettings }),
    [openSettings]
  );

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <OpenSettings.Provider value={value}>
      <Fade
        style={{ transitionDuration: "300ms" }}
        in={isMobile || !openSettings}
        unmountOnExit
      >
        <AnimateWr>
          <SearchBox
            resetFilterSearch={resetFilterSearch}
            setFilterSearch={setFilterSearch}
          />
          <Wrapper>
            <HandbookCardsContainer />
          </Wrapper>
        </AnimateWr>
      </Fade>
      {isMobile ? (
        <MobileSettings />
      ) : (
        <Fade
          in={openSettings}
          style={{ transitionDuration: "600ms" }}
          unmountOnExit
        >
          <Wrapper>
            <HbSettings />
          </Wrapper>
        </Fade>
      )}
    </OpenSettings.Provider>
  );
};

const AnimateWr = styled.div`
  width: 100%;
  margin: 0 !important;
`;
