import api from "@/services/api";
import {
  fetchFiltersError,
  fetchFiltersLoading,
  fetchFiltersSuccess,
} from "../fetchSlices/filtersSlice";

export const fetchFilters = (params = undefined) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFiltersLoading(true));
      const res = await api.get("api/fonts/filters", {
        params: {
          category: params?.category,
          subcategory: params?.subcategory,
          archetype: params?.archetype,
          license: params?.license,
          shape: params?.shape,
          script: params?.script,
          style: params?.style,
        },
      });
      dispatch(fetchFiltersLoading(false));

      if (res.status === 200) {
        dispatch(fetchFiltersSuccess(res.data));
      } else {
        dispatch(fetchFiltersError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchFiltersError(error.message));
      console.log(error);
    }
  };
};
