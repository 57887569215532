import SvgSelector from "@/shared/UI/SvgSelector";
import {
  CloseButton,
  Input,
  InputContainer,
  InputWrapper,
  LoupeButton,
} from "./components";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleSearchOpen } from "@/redux/searchInputSlice";
import { debounce, useMediaQuery } from "@mui/material";
import { useAccess } from "@/hooks/useAccess";
import { isFull } from "@/shared/constants/access";
import { breakpoints } from "@/styles/variables/variables";
import { PrevSearchPanel } from "../index-page/search/PrevSearchPanel";
import { useRouter } from "next/router";
import { isLibrary } from "@/shared/constants/routes";

export const Loupe = ({ searchOpen, setSearch, resetSearch }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const [showClose, setShowClose] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const [access] = useAccess();

  const input = useRef(null);

  const { pathname } = useRouter();

  const dispatch = useDispatch();

  const resetInput = useCallback(() => {
    input.current.value = "";
    setShowClose(false);
    setIsOverflow(false);
    dispatch(resetSearch());
  });

  const handleInput = () => {
    const inputElem = input.current;
    setIsOverflow(inputElem.scrollWidth > inputElem.clientWidth);
    setShowClose(inputElem.value.length > 1);
  };

  const fetchSearch = () => {
    const inputVal = input.current.value;
    if (inputVal.length > 2 || inputVal.length === 0)
      dispatch(setSearch(inputVal));
  };

  const debouncedFetchSearch = debounce(fetchSearch, 1500);

  return (
    <InputWrapper disabled={!isFull(access)} loading={false} open={searchOpen}>
      <InputContainer isOverflow={isOverflow} open={searchOpen}>
        <Input
          autocomplete="off"
          ref={input}
          onChange={() => {
            handleInput();
            debouncedFetchSearch();
          }}
          type="text"
          name="search"
          id="search-input"
        />
      </InputContainer>
      {searchOpen && (
        <CloseButton open={searchOpen} show={showClose} onClick={resetInput}>
          <SvgSelector svg={"close-search"} />
        </CloseButton>
      )}
      <LoupeButton
        tabindex="-1"
        open={searchOpen}
        onClick={() => {
          resetInput();
          dispatch(toggleSearchOpen(!searchOpen));
          dispatch(resetSearch());
        }}
      >
        <SvgSelector svg={"loupe"} />
      </LoupeButton>
      {isLibrary(pathname) && searchOpen && !isMobile && (
        <PrevSearchPanel
          currentInput={input.current}
          handleInput={handleInput}
          fetchSearch={fetchSearch}
        />
      )}
    </InputWrapper>
  );
};
