import { fetchFilters } from "@/redux/thunks/fetchFilters";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonTune, SpanReset } from "./components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { resetFilter } from "@/redux/filterSlice";
import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import { isFull } from "@/shared/constants/access";
import { useAccess } from "@/hooks/useAccess";
import { useRouter } from "next/router";
import { isFoundation, isHandbook, isLibrary } from "@/shared/constants/routes";

export const ButtonTuneWithReset = ({ openSearch, onClick, refMy }) => {
  const [text, setText] = useState(true);
  const { pathname } = useRouter();

  const data = useSelector((state) => state.filter);
  const { checked } = useSelector((state) => state.hbCheckedTags);
  const dispatch = useDispatch();

  const [access] = useAccess();

  const active = useMemo(
    () => Object.keys(data).some((key) => data[key] !== undefined),
    [data]
  );

  useEffect(() => {
    if (isFull(access) && isLibrary(pathname)) dispatch(fetchFilters(data));
  }, [data, access]);

  return (
    <ButtonTune
      tabindex="-1"
      disabled={!isFull(access)}
      data-type="tune"
      onMouseDown={(e) => (e.target.style.backgroundColor = "var(--wh04)")}
      onMouseUp={(e) => (e.target.style.backgroundColor = "")}
      active={active}
      hasChecked={checked.length > 0}
      openSearch={openSearch}
      onClick={onClick}
      ref={refMy}
    >
      {(isHandbook(pathname) || isFoundation(pathname)) &&
        `#${checked.length > 0 ? "" : " Настроить"}`}
      {isLibrary(pathname) && (
        <>
          <HoverTwoSpan active={text}>
            <SpanReset>Настроить</SpanReset>
            <SpanReset>Сбросить</SpanReset>
          </HoverTwoSpan>
          {active && (
            <button
              onMouseDown={(e) => e.stopPropagation()}
              onClick={() => {
                dispatch(resetFilter());
                setText(true);
              }}
              onMouseOver={() => setText(false)}
              onMouseOut={() => setText(true)}
            >
              <SvgSelector svg={"close-popup"} />
            </button>
          )}
        </>
      )}
    </ButtonTune>
  );
};
