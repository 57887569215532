import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const Box = styled.div`
  margin: 73px 0 44px;
  height: 42px;
  display: flex;
  justify-content: center;
  ${mixins.gap4}
  position: relative;
  &::before {
    ${({ disableAccess }) =>
      disableAccess
        ? `display: none;`
        : ` pointer-events: none;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            transition: width 0.3s;
            content: "";
            width: calc(250% + 200px);
            height: 600%;
            background-image: url("/assets/search-bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;`};
  }
  @media ${breakpoints.desktopXl} {
    margin: 5.708vw 0 3.44vw;
    height: 3.284vw;
  }
  @media ${breakpoints.tabletL} {
    margin: 7.136vw 0 4.301vw;
    height: 4.106vw;
  }
  @media ${breakpoints.tabletM} {
    margin: 9.825vw 0 5.922vw;
    height: 5.653vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 64.263vw;
    margin: 10.031vw 0 11.285vw 13.48vw;
    height: 13.166vw;
  }
  transition: margin 0.3s;
  ${({ disable }) =>
    disable &&
    `
    opacity: 0;
    pointer-events: none;
    margin: 42px 0 0 !important;
    @media ${breakpoints.mobile} {
      margin-top: 6.27vw !important;
    }
  `};
  ${({ open }) => open && `gap: 0 !important;`};
`;

// -- prev panel

export const PrevPanelSt = styled.div`
  ${fonts.manrope5}
  ${mixins.fs12};
  letter-spacing: 0.24px;
  position: absolute;
  bottom: -12px;
  transform: translateY(100%);
  left: 20px;
  display: flex;
  align-items: center;
  gap: 9.5px;
  max-width: 95%;
  text-overflow: ellipsis;
  line-height: normal;

  & > span {
    color: var(--wh2);
  }
  & > button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
    color: var(--wh4);
    transition: color 0.3s;
    max-width: 50%;
    line-height: normal;
    &:hover {
      color: var(--wh6);
    }
    &:active {
      color: var(--wh4);
    }
  }
`;
