import { setIndexOfOverflow } from "@/redux/fetchSlices/hbCheckedTagsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useIndexOfOverflow = (tagBox, wrapper, tagsArr) => {
  const dispatch = useDispatch();
  const { indexOfOverflow } = useSelector((state) => state.hbCheckedTags);

  useEffect(() => {
    function handleResize() {
      const maxWidth = wrapper.current.clientWidth;
      if (maxWidth > tagBox.current.clientWidth) {
        dispatch(setIndexOfOverflow(tagsArr.length));
      }
    }
    handleResize();
    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, [tagsArr.length]);

  return { indexOfOverflow };
};
