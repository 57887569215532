import { getRandomArbitrary } from "@/services/utils";
import { HbWr, WrGap12, WrGap4Share, WrGap4Tags, WrGap8 } from "./components";
import { Skelet } from "@/shared/UI/Skelet";
import { useIndexByQuery } from "@/hooks/useIndexByQuery";
import { ResponsiveMasonry } from "react-responsive-masonry";
import { MasonryWithGap } from "../../components";
import { columns } from "../../masonry/HandbookCardsContainer";

const HbCardSkeleton = () => (
  <HbWr is_active={true} height={getRandomArbitrary(400)}>
    <WrGap12>
      <span>
        <Skelet />
      </span>
      <WrGap4Tags>
        {Array.from(Array(3)).map((_, i) => (
          <span key={i}>
            <Skelet />
          </span>
        ))}
      </WrGap4Tags>
      <WrGap8>
        {Array.from(Array(3)).map((_, i) => (
          <span key={i}>
            <Skelet />
          </span>
        ))}
      </WrGap8>
      <WrGap4Share>
        {Array.from(Array(5)).map((_, i) => (
          <span key={i}>
            <Skelet />
          </span>
        ))}
      </WrGap4Share>
      <span>
        <Skelet />
      </span>
    </WrGap12>
  </HbWr>
);

export const HbCardsSkeleton = () => {
  const { index } = useIndexByQuery();

  return (
    <ResponsiveMasonry columnsCountBreakPoints={columns}>
      <MasonryWithGap>
        {Array.from(Array(index)).map((_, i) => (
          <HbCardSkeleton key={i} />
        ))}
      </MasonryWithGap>
    </ResponsiveMasonry>
  );
};
