import Markdown from "react-markdown";
import styled from "styled-components";
import rehypeRaw from "rehype-raw";

const Mark = styled.span`
  color: var(--wh9);
  background-color: var(--wh12);
  border-radius: 4px;
  padding: 0 0.2em;
`;
export const HighlightParser = ({ children }) => {
  return (
    <Markdown rehypePlugins={[rehypeRaw]} components={{ mark: Mark }}>
      {children}
    </Markdown>
  );
};
