import api from "@/services/api";

import {
  fetchHbCardsError,
  fetchHbCardsLoading,
  fetchHbCardsSuccess,
} from "../fetchSlices/hbCardsSlice";

export const fetchHbCards = (
  offset = 0,
  index = 10,
  filterParams = [],
  isBookmarked = false,
  search = undefined,
  isFoundation = false
) => {
  return async (dispatch) => {
    try {
      const params = new URLSearchParams();
      if (filterParams.length > 0) {
        filterParams.forEach((value) => {
          params.append(`tags`, value.id);
        });
      }

      params.append("limit", index);
      params.append("offset", offset * index);
      if (search) {
        params.append("search", search);
      }
      if (isBookmarked) {
        params.append("is_bookmarked", true);
      }

      dispatch(fetchHbCardsLoading(true));
      const res = await api.get(
        `api/${isFoundation ? "artpreparation" : "handbook"}/cards/`,
        {
          params,
        }
      );
      dispatch(fetchHbCardsLoading(false));

      if (res.status === 200) {
        dispatch(fetchHbCardsSuccess(res.data));
      } else {
        dispatch(fetchHbCardsError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchHbCardsError(error.message));
      console.log(error);
    }
  };
};
