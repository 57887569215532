import { ErrorLink } from "@/components/footer/team/components";
import { IsNew } from "@/components/index-page/cards/card/IsNew";
import { helpLink, siteUrlWithoutHttps } from "@/shared/constants/links";
import { isJsonRegexp, isSvgRegexp } from "@/shared/constants/regexp";
import { isHandbook } from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import { Zoom, useMediaQuery } from "@mui/material";
import { useInViewport } from "ahooks";
import { useRouter } from "next/router";
import { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OpenSettings } from "../AnimateSettingsWr";
import { Authors } from "../Authors";
import { HighlightParser } from "../HighlightParser";
import {
  Desc_HbCard,
  HandbookCardSt,
  Title_HbCard,
  WrPadding,
} from "../components";
import { FavButton } from "./FavButton";
import { FoundationCardHeader } from "./FoundationCardHeader";
import {
  HbCardHeaderAnimated,
  HbCardHeaderAnimatedMobile,
} from "./HbCardHeaderAnimated";
import { ShareButtons } from "./ShareButtons";
import { SlugLink } from "./SlugLink";
import { TagsHbCard } from "./TagsHbCard";

export const HandbookCard = ({ data }) => {
  const { openSettings } = useContext(OpenSettings);

  const { isBookmarked: bookmarkMode } = useSelector((state) => state.hbCards);

  const [paused, setPaused] = useState(true);

  const { pathname } = useRouter();
  const isMobile = useMediaQuery(breakpoints.mobile);

  const card = useRef(null);

  const [inViewport] = useInViewport(isMobile ? card : null, {
    threshold: 0.7,
  });

  return (
    <Zoom
      in={!openSettings}
      style={{ transitionDuration: openSettings ? "500ms" : "100ms" }}
      unmountOnExit
    >
      <HandbookCardSt
        hovered={inViewport}
        ref={card}
        disableInBookmarkMode={bookmarkMode ? !data.is_bookmarked : undefined}
        onMouseEnter={() => setPaused(false)}
        onMouseLeave={() => setPaused(true)}
        is_active={data.is_active}
      >
        {isJsonRegexp.test(data.image) || isSvgRegexp.test(data.image) ? (
          isMobile ? (
            <HbCardHeaderAnimatedMobile
              circle_color={data.color}
              animation={data.image_data}
              image={data.image}
              slug={data.slug}
            />
          ) : (
            <HbCardHeaderAnimated
              circle_color={data.color}
              animation={data.image_data}
              image={data.image}
              paused={paused}
              slug={data.slug}
            />
          )
        ) : (
          <FoundationCardHeader
            hovered={!paused}
            image={data.image}
            slug={data.slug}
          />
        )}
        <IsNew date={data.date_published} />
        <FavButton is_bookmarked={data.is_bookmarked} slug={data.slug} />
        <SlugLink slug={data.slug} />
        <TagsHbCard data={data.tags} />
        <WrPadding>
          <Title_HbCard>
            {data.title_highlight ? (
              <HighlightParser>{data.title_highlight}</HighlightParser>
            ) : (
              data.title
            )}
          </Title_HbCard>
          <Desc_HbCard>
            {data.short_text_highlight ? (
              <HighlightParser>{data.short_text_highlight}</HighlightParser>
            ) : (
              data.short_text
            )}
          </Desc_HbCard>
          {data.authors?.length > 0 && <Authors data={data.authors} />}
        </WrPadding>

        <ShareButtons
          copy_link={`${siteUrlWithoutHttps}/${
            isHandbook(pathname) ? "handbook" : "artpreparation"
          }/${data.slug}`}
          description={data.short_text}
          title={data.title}
        />
        <ErrorLink href={helpLink} target="_blank" rel="noopener noreferrer">
          <span>Сообщить об ошибке</span>
        </ErrorLink>
      </HandbookCardSt>
    </Zoom>
  );
};
