import { Circle } from "@/components/campus/classroom/graphic-metaphors/index/CardLocked";
import { breakpoints, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { HandbookCardSt } from "../../components";

export const WrGap12 = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  ${mixins.gap12}
  & span {
    background-color: rgba(255, 255, 255, 0.02);
  }
  & > span {
    &:first-child {
      border-radius: 24px;
      height: 172px;
      @media ${breakpoints.desktopXl} {
        height: 13.448vw;
      }
      @media ${breakpoints.tabletL} {
        height: 16.813vw;
      }
      @media ${breakpoints.tabletM} {
        height: 23.149vw;
      }
      @media ${breakpoints.mobile} {
        height: 53.918vw;
      }
    }
    &:last-child {
      border-radius: 6px;
      height: 10px;
      width: 35%;
      margin: 0 auto;
      @media ${breakpoints.desktopXl} {
        height: 0.782vw;
      }
      @media ${breakpoints.tabletL} {
        height: 0.978vw;
      }
      @media ${breakpoints.tabletM} {
        height: 1.346vw;
      }
      @media ${breakpoints.mobile} {
        height: 3.135vw;
      }
    }
  }

  & .MuiSkeleton-root {
    border-radius: inherit;
  }
`;

export const HbWr = styled(HandbookCardSt)`
  cursor: pointer;

  ${Circle} {
    & #lock-top {
      transition: transform 0.3s;
      transform-origin: 75% center;
    }
    top: 1.7vw;
  }
  &:hover {
    ${Circle}>div {
      opacity: 0.28;
    }
  }
  &:active {
    ${Circle} {
      & > div {
        opacity: 0.16;
      }
      & #lock-top {
        transform: scale(-1, 1);
      }
    }
  }
  @media ${breakpoints.mobile} {
    ${({ hovered }) =>
      hovered &&
      `
        ${Circle} > div {
          opacity: 0.28;
        }
  `}
  }
  ${({ height }) => `height: ${height}px;
  
  @media ${breakpoints.desktopXl} {
    height: ${height * 0.078}vw;
  }
  @media ${breakpoints.tabletL} {
    height: ${height * 0.098}vw;
  }
  @media ${breakpoints.tabletM} {
    height: ${height * 0.1346}vw;
  }
  @media ${breakpoints.mobile} {
    height: ${height * 0.31348}vw;
  }
    `}
`;
export const WrGap8 = styled.div`
  display: flex;
  flex-direction: column;
  ${mixins.gap8}
  height: max-content;
  flex-grow: 1;
  & > span {
    &:first-child {
      height: 40px;
      width: 80%;
      border-radius: 8px;
      @media ${breakpoints.desktopXl} {
        height: 3.127vw;
      }
      @media ${breakpoints.tabletL} {
        height: 3.91vw;
      }
      @media ${breakpoints.tabletM} {
        height: 5.384vw;
      }
      @media ${breakpoints.mobile} {
        height: 12.539vw;
      }
    }
    &:nth-child(2) {
      border-radius: 12px;
      height: 100%;
    }
    &:nth-child(3) {
      border-radius: 6px;
      width: 75%;
      height: 20px;
      @media ${breakpoints.desktopXl} {
        height: 1.564vw;
      }
      @media ${breakpoints.tabletL} {
        height: 1.955vw;
      }
      @media ${breakpoints.tabletM} {
        height: 2.692vw;
      }
      @media ${breakpoints.mobile} {
        height: 6.27vw;
      }
    }
  }
`;
export const WrGap4 = styled.div`
  display: flex;
  flex-direction: row;
  ${mixins.gap4}
`;
export const WrGap4Tags = styled(WrGap4)`
  height: 18px;
  width: 70%;
  @media ${breakpoints.desktopXl} {
    height: 1.407vw;
  }
  @media ${breakpoints.tabletL} {
    height: 1.76vw;
  }
  @media ${breakpoints.tabletM} {
    height: 2.423vw;
  }
  @media ${breakpoints.mobile} {
    height: 5.643vw;
  }
  & > span {
    border-radius: 6px;
    height: 100%;
    width: 100%;
  }
`;
export const WrGap4Share = styled(WrGap4)`
  height: 24px;
  width: 85%;
  @media ${breakpoints.desktopXl} {
    height: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    height: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    height: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    height: 7.524vw;
  }

  & > span {
    width: 45%;
    height: 100%;
    border-radius: 25%;
    &:first-child {
      flex-shrink: 0;
    }
  }
`;
