import { Zoom } from "@mui/material";
import { ButtonMore, MenuSt, Tag, Tags } from "./components";
import { useState } from "react";
import SvgSelector from "@/shared/UI/SvgSelector";
import { useDispatch } from "react-redux";
import { deleteHbCheckedTag } from '@/redux/fetchSlices/hbCheckedTagsSlice';

export const MoreSelect = ({ tags }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonMore
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <div>...</div>
      </ButtonMore>
      <MenuSt
        TransitionComponent={Zoom}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="select-tag-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Tags>
          {tags.map((tag) => (
            <Tag
              key={tag.id}
              onClick={() => dispatch(deleteHbCheckedTag(tag))}
              disableRipple
            >
              <div>{tag.name}</div>
              <SvgSelector svg="close-search" />
            </Tag>
          ))}
        </Tags>
      </MenuSt>
    </>
  );
};
