import { useEffect } from "react";
import { TagSm, Tags, TagsTitle, TagsWr, Wr } from "./components";
import { Zoom } from "@mui/material";
import { Checked } from "./Checked";
import { useDispatch, useSelector } from "react-redux";
import { fetchHbTags } from "@/redux/thunks/fetchHbTags";
import { Sections } from "./Sections";
import { BottomButton } from "./BottomButton";
import { useRouter } from "next/router";
import { isFoundation } from "@/shared/constants/routes";
import { addHbCheckedTag } from "@/redux/fetchSlices/hbCheckedTagsSlice";

export const HbSettings = () => {
  const dispatch = useDispatch();
  const { related, popular, loading } = useSelector((state) => state.hbTags);
  const { checked } = useSelector((state) => state.hbCheckedTags);

  const { pathname } = useRouter();

  const existChecked = checked.length > 0;

  useEffect(() => {
    if (existChecked) {
      dispatch(fetchHbTags(checked, isFoundation(pathname) ? true : false));
    } else {
      dispatch(fetchHbTags(undefined, isFoundation(pathname) ? true : false));
    }
  }, [checked]);

  return (
    <Wr>
      <TagsWr>
        <TagsTitle>{existChecked ? "Выбранные" : "Области"}</TagsTitle>
        {existChecked ? <Checked /> : <Sections />}
      </TagsWr>
      <TagsWr>
        <TagsTitle>
          {existChecked
            ? related.length > 0
              ? "Связанные"
              : ""
            : popular.length > 0
              ? "Популярное"
              : ""}
        </TagsTitle>
        <Tags>
          {loading ||
            (existChecked ? related : popular).map((tag, i) => (
              <Zoom
                style={{ transitionDelay: `${100 + 40 * i}ms` }}
                in={true}
                key={tag.id}
              >
                <TagSm onClick={() => dispatch(addHbCheckedTag(tag))}>
                  <div>{tag.name}</div>
                </TagSm>
              </Zoom>
            ))}
        </Tags>
      </TagsWr>
      <BottomButton />
    </Wr>
  );
};
