import { breakpoints } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";

export const useColumnCount = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const isTabletM = useMediaQuery(breakpoints.tabletM);
  const isTabletL = useMediaQuery(breakpoints.tabletL);
  const isDesktopXl = useMediaQuery(breakpoints.desktopXl);

  let columnCount = 5;
  if (isMobile) {
    columnCount = 1;
  } else if (isTabletM) {
    columnCount = 2;
  } else if (isTabletL) {
    columnCount = 3;
  } else if (isDesktopXl) {
    columnCount = 4;
  }

  return { columnCount };
};
