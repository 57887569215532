import { useContext, useRef, useState } from "react";
import { ButtonWithLabel } from "@/shared/UI/ButtonWithLabel";
import { ButtonTuneWithReset } from "./ButtonTuneWithReset";
import { useInViewport } from "ahooks";
import { OpenSettings } from "@/components/handbook/AnimateSettingsWr";
import { useRouter } from "next/router";
import { isFoundation, isHandbook } from "@/shared/constants/routes";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";

export const PopupSettings = ({ openSearch, SearchDialog }) => {
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const handleOpen = (e) => {
    if (e.target.dataset.type === "tune") setOpen(true);
  };

  const btn = useRef(null);
  const [hideUp] = useInViewport(btn);

  const { pathname } = useRouter();

  let contextHb;
  if (isHandbook(pathname) || isFoundation(pathname)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    contextHb = useContext(OpenSettings);
  }

  return (
    <>
      {contextHb?.openSettings || (
        <ButtonTuneWithReset
          refMy={btn}
          openSearch={openSearch}
          onClick={
            contextHb ? () => contextHb.setOpenSettings(true) : handleOpen
          }
        />
      )}
      {SearchDialog && (
        <SearchDialog open={open} handleClose={() => setOpen(false)} />
      )}
      {isMobile || (
        <ButtonWithLabel
          hide={hideUp}
          location="cr"
          handleClick={() => window.scroll(0, 0)}
          svg={"footer-arrow-up"}
        >
          Наверх
        </ButtonWithLabel>
      )}
    </>
  );
};
