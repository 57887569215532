import { isJsonRegexp } from "@/shared/constants/regexp";
import { breakpoints } from "@/styles/variables/variables";
import { useInViewport } from "ahooks";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { Picture_HbCard, Slug } from "../components";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

export const HbCardHeaderAnimated = ({
  circle_color,
  paused,
  animation,
  image,
  slug,
}) => {
  const lottieRef = useRef(null);

  const { pathname } = useRouter();

  const parsedAnimation = useMemo(
    () => (isJsonRegexp.test(image) ? JSON.parse(animation) : null),
    []
  );

  useEffect(() => {
    if (lottieRef.current) {
      if (!paused) {
        lottieRef.current.play();
      }
    }
  }, [paused]);

  return (
    <Wr
      // target="_blank"
      href={pathname + "/" + slug}
    >
      <Picture_HbCard circle={circle_color} />
      {isJsonRegexp.test(image) ? (
        <LottieWr>
          <Lottie
            rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
            lottieRef={lottieRef}
            animationData={parsedAnimation}
            loop={true}
            onLoopComplete={() => {
              if (paused && lottieRef.current) lottieRef.current.stop();
            }}
            autoplay={false}
          />
        </LottieWr>
      ) : (
        image && <SvgImage data-svg="animate-svg" src={image} />
      )}
    </Wr>
  );
};
export const HbCardHeaderAnimatedMobile = ({
  circle_color,
  animation,
  image,
  slug,
}) => {
  const lottieRef = useRef(null);
  const lottieWr = useRef(null);

  const [show] = useInViewport(lottieWr);

  const { pathname } = useRouter();

  const parsedAnimation = useMemo(
    () => (isJsonRegexp.test(image) ? JSON.parse(animation) : null),
    []
  );

  useEffect(() => {
    if (lottieRef.current) {
      if (show) {
        lottieRef.current.play();
      } else {
        lottieRef.current.stop();
      }
    }
  }, [show]);

  return (
    <Wr
      // target="_blank"
      href={pathname + "/" + slug}
    >
      <Picture_HbCard circle={circle_color} />
      {isJsonRegexp.test(image) ? (
        <LottieWr ref={lottieWr}>
          <Lottie
            rendererSettings={{ preserveAspectRatio: "xMidYMid meet" }}
            lottieRef={lottieRef}
            animationData={parsedAnimation}
            loop={true}
            autoplay={true}
          />
        </LottieWr>
      ) : (
        image && <SvgImage data-svg="animate-svg" src={image} />
      )}
    </Wr>
  );
};

const Wr = styled.a`
  display: block;
  position: relative;
  /* z-index: -1; */
  height: 173px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.938vw;
    height: 13.526vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.173vw;
    height: 16.911vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.615vw;
    height: 23.284vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.762vw;
    height: 54.232vw;
  }
  &:hover {
    & ~ ${Slug} #slug-bg {
      fill-opacity: 0.08;
    }
  }
  &:active {
    & ~ ${Slug} #slug-bg {
      transform: scale(0.9);
    }
  }
`;
const LottieWr = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 50%;
`;
const SvgImage = styled.img`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  object-fit: contain;
  transition: transform 0.3s;
  transform-origin: left top;
`;
