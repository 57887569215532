import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { OpenSettings } from "../AnimateSettingsWr";
import { useContext } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { resetHbCheckedTags } from '@/redux/fetchSlices/hbCheckedTagsSlice';

export const BottomButton = () => {
  const dispatch = useDispatch();

  const { setOpenSettings } = useContext(OpenSettings);
  const isMobile = useMediaQuery(breakpoints.mobile);

  const { checked } = useSelector((state) => state.hbCheckedTags);
  const existChecked = checked.length > 0;

  return isMobile ? (
    existChecked ? (
      <Wr>
        <MobileButtonClose onClick={() => dispatch(resetHbCheckedTags())}>
          Сбросить
        </MobileButtonClose>
        <MobileButtonApply onClick={() => setOpenSettings(false)}>
          Применить
        </MobileButtonApply>
      </Wr>
    ) : (
      <ButtonClose onClick={() => setOpenSettings(false)}>Закрыть</ButtonClose>
    )
  ) : (
    <ButtonClose
      onClick={() =>
        existChecked ? dispatch(resetHbCheckedTags()) : setOpenSettings(false)
      }
    >
      {existChecked ? "Сбросить" : "Закрыть"}
    </ButtonClose>
  );
};

const ButtonClose = styled.button`
  position: absolute;
  z-index: 99;
  bottom: 0;
  ${fonts.manrope4}
  ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--wh4);
  transition: color 0.3s;
  /* &::before {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    transition: opacity 0.3s;
    content: "";
    width: 300px;
    height: 200px;
    background-image: url("/assets/close-hb-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
    @media ${breakpoints.mobile} {
      display: none;
    }
  } */
  &:hover {
    color: var(--wh3);

    /* &::before {
      opacity: 1;
    } */
  }
  &:active {
    color: var(--wh2);
  }
  padding: 47px;
  @media ${breakpoints.desktopXl} {
    padding: 3.264vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 5.635vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 5.635vw;
  }
  @media ${breakpoints.mobile} {
    padding: 10.345vw 3.135vw;
    font-size: 4.389vw;
    line-height: 114%;
    letter-spacing: 0.01em;
    color: var(--wh6);
  }
`;

const MobileButtonClose = styled(ButtonClose)`
  position: static;
  padding: 5.016vw 10.502vw 5.643vw;
`;
const MobileButtonApply = styled(MobileButtonClose)`
  background-color: var(--wh04);
  border-radius: 5.016vw;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh08);
  }
  padding: 5.016vw 13.793vw 5.643vw;
`;

const Wr = styled.div`
  display: flex;
  align-items: center;
  gap: 1.254vw;
  position: absolute;
  bottom: 4.906vw;
`;
