import { PrevPanelSt } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { removePrevSearch } from "@/redux/prevSearchSlice";

export const PrevSearchPanel = ({ currentInput, handleInput, fetchSearch }) => {
  const dispatch = useDispatch();
  const { data: searches } = useSelector((state) => state.prevSearch);

  const handlePrevSearchClick = (prev) => {
    currentInput.value = prev;
    handleInput();
    dispatch(removePrevSearch(prev));
    fetchSearch();
  };

  return (
    <>
      {searches.length > 0 && (
        <PrevPanelSt>
          <span>Вы искали:</span>
          {searches.map((prev, i) => (
            <button key={i} onClick={() => handlePrevSearchClick(prev)}>
              {prev}
            </button>
          ))}
        </PrevPanelSt>
      )}
    </>
  );
};
