import { resetHbCheckedTags } from '@/redux/fetchSlices/hbCheckedTagsSlice';
import { mixins } from "@/styles/variables/variables";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

export const CheckedButtonMobile = () => {
  const dispatch = useDispatch();
  const { checked } = useSelector((state) => state.hbCheckedTags);
  const { searchOpen } = useSelector((state) => state.searchInput);

  const tagString = checked.map((el) => el.name).join(" · ");

  return (
    <Button hide={searchOpen} onClick={() => dispatch(resetHbCheckedTags())}>
      {tagString}
    </Button>
  );
};

const Button = styled.button`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: var(--wh04);
  border-radius: 24px;
  line-height: 114%;
  letter-spacing: 0.01em;
  color: var(--wh6);
  text-align: left;
  ${mixins.fs14}
  flex-shrink: 100;
  transition: padding 0.3s;
  ${({ hide }) => `padding: ${hide ? "0" : "0 8.464vw 0 5.329vw"};`}
  position: relative;
  margin-right: 1.254vw;
  &::after {
    content: "×";
    position: absolute;
    font-size: 4.389vw;
    line-height: 114%;
    color: var(--wh3);
    letter-spacing: 0.01em;
    right: 5.329vw;
  }
`;
