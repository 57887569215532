import { useContext, useRef } from "react";
import { Zoom, useMediaQuery } from "@mui/material";
import { Tag, TagBoxHidden, TagsChecked, WrChecked } from "./components";
import { MoreSelect } from "./MoreSelect";
import { useDispatch, useSelector } from "react-redux";
import { useIndexOfOverflow } from "@/hooks/useIndexOfOverflow";
import { OpenSettings } from "../AnimateSettingsWr";
import { breakpoints } from "@/styles/variables/variables";
import { ApplyButton } from "./ApplyButton";
import { deleteHbCheckedTag } from "@/redux/fetchSlices/hbCheckedTagsSlice";

export const Checked = ({ isSettings = true }) => {
  const { openSettings } = useContext(OpenSettings);

  const dispatch = useDispatch();
  const tagBox = useRef(null);
  const wrapper = useRef(null);

  const { checked } = useSelector((state) => state.hbCheckedTags);
  const { searchOpen } = useSelector((state) => state.searchInput);
  const { indexOfOverflow } = useIndexOfOverflow(tagBox, wrapper, checked); // to context and запомнить глобально при выходе в карточки

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <>
      <WrChecked>
        <TagBoxHidden ref={tagBox}>
          {checked.map((tag) => (
            <Tag key={tag.id}>
              <div>{tag.name}</div>
            </Tag>
          ))}
        </TagBoxHidden>
        <TagsChecked
          isSettings={isSettings}
          // addPadding={openSettings}
          addMargin={!openSettings}
          hideOnSearchOpen={!openSettings && searchOpen}
          ref={wrapper}
        >
          {isMobile ? (
            <>
              {checked.map((tag, i) => (
                <Zoom
                  style={{ transitionDelay: `${100 + 40 * i}ms` }}
                  in={true}
                  key={tag.id}
                >
                  <Tag
                    withCross={true}
                    hide={false}
                    onClick={() => dispatch(deleteHbCheckedTag(tag))}
                  >
                    <div>
                      <span>{tag.name}</span>
                    </div>
                  </Tag>
                </Zoom>
              ))}
            </>
          ) : (
            <>
              {openSettings && <ApplyButton />}
              {checked.slice(0, indexOfOverflow).map((tag, i) => (
                <Zoom
                  style={{ transitionDelay: `${100 + 40 * i}ms` }}
                  in={true}
                  key={tag.id}
                >
                  <Tag
                    withCross={true}
                    hide={i > indexOfOverflow}
                    onClick={() => dispatch(deleteHbCheckedTag(tag))}
                  >
                    <div>
                      <span>{tag.name}</span>
                    </div>
                  </Tag>
                </Zoom>
              ))}
              {checked.slice(indexOfOverflow).length > 0 && (
                <MoreSelect tags={checked.slice(indexOfOverflow)} />
              )}
            </>
          )}
        </TagsChecked>
      </WrChecked>
    </>
  );
};
