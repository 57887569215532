import { Circle } from "@/components/campus/classroom/graphic-metaphors/index/CardLocked";
import { LoginPopup } from "@/components/header/LoginPopup";
import { useIndexByQuery } from "@/hooks/useIndexByQuery";
import { getRandomArbitrary } from "@/services/utils";
import SvgSelector from "@/shared/UI/SvgSelector";
import { useInViewport } from "ahooks";
import { useMemo, useRef, useState } from "react";
import { ResponsiveMasonry } from "react-responsive-masonry";
import { MasonryWithGap } from "../../components";
import { columns } from "../../masonry/HandbookCardsContainer";
import { HbWr, WrGap12, WrGap4Share, WrGap4Tags, WrGap8 } from "./components";

const HbCardLocked = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const onOpen = () => setOpenDialog(true);

  const card = useRef(null);
  const [inViewport] = useInViewport(card, { threshold: 0.7 });

  const height = useMemo(() => getRandomArbitrary(), []);

  return (
    <>
      <HbWr
        hovered={inViewport}
        ref={card}
        onClick={onOpen}
        is_active={true}
        height={height}
      >
        <WrGap12>
          <span>
            <Circle>
              <div></div>
              <SvgSelector svg={"campus-card-locked-lock"} />
            </Circle>
          </span>
          <WrGap4Tags>
            <span></span>
            <span></span>
            <span></span>
          </WrGap4Tags>
          <WrGap8>
            <span></span>
            <span></span>
            <span></span>
          </WrGap8>
          <WrGap4Share>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </WrGap4Share>
          <span></span>
        </WrGap12>
      </HbWr>
      <LoginPopup open={openDialog} setOpen={setOpenDialog} />
    </>
  );
};

export const HbCardsLocked = () => {
  const { index } = useIndexByQuery();

  return (
    <ResponsiveMasonry columnsCountBreakPoints={columns}>
      <MasonryWithGap>
        {Array.from(Array(index)).map((el, i) => (
          <HbCardLocked key={i} />
        ))}
      </MasonryWithGap>
    </ResponsiveMasonry>
  );
};
