import { Tag, Tags } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { Zoom } from "@mui/material";
import { addHbCheckedTag } from "@/redux/fetchSlices/hbCheckedTagsSlice";

export const Sections = () => {
  const { sections } = useSelector((state) => state.hbTags);
  const dispatch = useDispatch();

  return (
    <Tags>
      {sections.map((tag, i) => (
        <Zoom
          style={{ transitionDelay: `${100 + 40 * i}ms` }}
          in={true}
          key={tag.id}
        >
          <Tag onClick={() => dispatch(addHbCheckedTag(tag))}>
            <div>{tag.name}</div>
          </Tag>
        </Zoom>
      ))}
    </Tags>
  );
};
