import SvgSelector from "@/shared/UI/SvgSelector";
import { Fav } from "../components";
import { useCallback } from "react";
import api from "@/services/api";
import { useDispatch } from "react-redux";
import { setHbCardBookmarkedBySlug } from "@/redux/fetchSlices/hbCardsSlice";
import { useRouter } from "next/router";
import { isHandbook } from "@/shared/constants/routes";

export const FavButton = ({ is_bookmarked, slug }) => {
  const dispatch = useDispatch();

  const { pathname } = useRouter();

  const putHbCardtoFav = useCallback(() => {
    try {
      is_bookmarked
        ? api.delete(
            `api/${
              isHandbook(pathname) ? "handbook" : "artpreparation"
            }/cards/${slug}/bookmark`
          )
        : api.put(
            `api/${
              isHandbook(pathname) ? "handbook" : "artpreparation"
            }/cards/${slug}/bookmark`
          );
      dispatch(setHbCardBookmarkedBySlug({ slug: slug }));
    } catch (error) {
      console.log("error: ", error);
    }
  }, [is_bookmarked]);

  return (
    <Fav is_bookmarked={is_bookmarked} onClick={putHbCardtoFav}>
      <SvgSelector svg="hb-card-fav" />
    </Fav>
  );
};
