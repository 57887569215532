import { useContext } from "react";
import { OpenSettings } from "../AnimateSettingsWr";
import { breakpoints, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const ApplyButton = () => {
  const { setOpenSettings } = useContext(OpenSettings);

  return <Button onClick={() => setOpenSettings(false)}>→</Button>;
};

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  ${mixins.fs16};
  line-height: 112%;
  backdrop-filter: blur(27px);
  -webkit-backdrop-filter: blur(27px);
  border-radius: 22px;
  margin-right: 4px;
  @media ${breakpoints.desktopXl} {
    margin-right: 0.313vw;
    border-radius: 1.72vw;
    width: 3.44vw;
    height: 3.44vw;
  }
  @media ${breakpoints.tabletL} {
    margin-right: 0.391vw;
    border-radius: 2.151vw;
    width: 4.301vw;
    height: 4.301vw;
  }
  @media ${breakpoints.tabletM} {
    margin-right: 0.538vw;
    border-radius: 2.961vw;
    width: 5.922vw;
    height: 5.922vw;
  }
  @media ${breakpoints.mobile} {
    margin-right: 1.254vw;
    border-radius: 6.897vw;
    width: 13.793vw;
    height: 13.793vw;
  }
  color: var(--wh6);
  background-color: var(--wh04);
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh08);
  }
  &:active {
    background-color: var(--wh04);
  }
`;
