import { CardNotFound } from "@/components/index-page/search/CardNotFound";
import { useAccess } from "@/hooks/useAccess";
import { useColumnCount } from "@/hooks/useColumnCount";
import { useIndexByQuery } from "@/hooks/useIndexByQuery";
import { resetHbCardsToEmpty } from "@/redux/fetchSlices/hbCardsSlice";
import { fetchHbCards } from "@/redux/thunks/fetchHbCards";
import { isOverdue, isUnknown } from "@/shared/constants/access";
import { isFoundation } from "@/shared/constants/routes";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveMasonry } from "react-responsive-masonry";
import { HandbookCard } from "../card/HandbookCard";
import { HbCardsLocked } from "../card/alternateCards/HbLocked";
import { HbCardsSkeleton } from "../card/alternateCards/HbSkeleton";
import { FirstLineContainer, MasonryWithGap } from "../components";
import { HbInfiniteScroll } from "./InfiniteScroll";

export const columns = {
  1727: 5,
  1278: 4,
  1023: 3,
  743: 2,
  319: 1,
};

const HandbookCardsContainer = () => {
  const [access] = useAccess();

  const { index } = useIndexByQuery();

  const [offset, setOffset] = useState(0);

  const { columnCount } = useColumnCount();

  const { pathname } = useRouter();

  const dispatch = useDispatch();
  const {
    hbCheckedTags: { checked },
    hbCards: { isBookmarked, loading, data },
    searchInput: { filterSearch, searchOpen },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(resetHbCardsToEmpty());
    dispatch(
      fetchHbCards(
        0,
        index,
        isBookmarked || filterSearch ? undefined : checked, // ignore filter if another mode
        isBookmarked,
        filterSearch,
        isFoundation(pathname) ? true : false
      )
    );
    setOffset(0);
  }, [checked, isBookmarked, filterSearch]);

  return (
    <>
      {isOverdue(access) || isUnknown(access) ? (
        <HbCardsLocked />
      ) : (
        loading && !searchOpen && <HbCardsSkeleton />
      )}
      {(searchOpen &&
        (filterSearch === undefined || filterSearch.trim() === "")) ||
        (data.length <= columnCount ? (
          <FirstLineContainer>
            {data.map((card) => (
              <HandbookCard key={card.slug} data={card} />
            ))}
          </FirstLineContainer>
        ) : (
          <HbInfiniteScroll
            dataLength={data.length}
            offset={offset}
            setOffset={setOffset}
          >
            <ResponsiveMasonry columnsCountBreakPoints={columns}>
              <MasonryWithGap>
                {data.map((card) => (
                  <HandbookCard key={card.slug} data={card} />
                ))}
              </MasonryWithGap>
            </ResponsiveMasonry>
          </HbInfiniteScroll>
        ))}
      {/* {loading && !searchOpen && <HbCardsSkeleton />} */}
      {data.length === 0 && !loading && (
        <CardNotFound bookmarkMode={isBookmarked} isHandbook={true} />
      )}
    </>
  );
};

export default HandbookCardsContainer;
