import { useEffect } from "react";
import { PopupSettings } from "../index-page/search/popup/PopupSettings";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearchOpen } from "@/redux/searchInputSlice";
import { useAccess } from "@/hooks/useAccess";
import { isFull } from "@/shared/constants/access";
import { Box } from "./components";
import { Loupe } from "./Loupe";
import { useRouter } from "next/router";
import { Checked } from "../handbook/filterTags/Checked";
import { isFoundation, isHandbook } from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";
import { CheckedButtonMobile } from "../handbook/filterTags/CheckedButtonMobile";

export const SearchBox = ({
  SearchDialog = undefined,
  resetFilterSearch,
  setFilterSearch,
}) => {
  const dispatch = useDispatch();

  const [access] = useAccess();

  const { pathname } = useRouter();

  const {
    hbCards: { isBookmarked: hbBookmarked },
    cards: { isBookmarked },
    searchInput: { searchOpen },
    hbCheckedTags: { checked },
  } = useSelector((state) => state);

  const handleKeyDown = (e) =>
    e.key === "Escape" && dispatch(toggleSearchOpen(!searchOpen));

  useEffect(() => {
    if (searchOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [searchOpen]);

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <Box
      disable={isBookmarked || hbBookmarked}
      open={searchOpen}
      disableAccess={!isFull(access)}
    >
      <PopupSettings openSearch={searchOpen} SearchDialog={SearchDialog} />
      {(isHandbook(pathname) || isFoundation(pathname)) &&
        checked.length > 0 &&
        (isMobile ? <CheckedButtonMobile /> : <Checked isSettings={false} />)}
      <Loupe
        searchOpen={searchOpen}
        setSearch={setFilterSearch}
        resetSearch={resetFilterSearch}
      />
    </Box>
  );
};
